<template>
  <div>
    <CRow class="m-0">
      <CCol cols="6" xl="6" class="pb-0 text-left">
        <CButton class="btn_small m-0" color="primary" @click="openSidebarRight('marketplace_set_details', { marketplace_set_id_external: null, product_tag: 'learn' });">    
          <span><i class="fas fa-plus mr-1"/>{{ $t('common.Add_marketplace_set') }}</span>
        </CButton>
      </CCol>     
    </CRow>
    <CRow>
      <CCol cols="12" lg="12" :class="{'pb-0' : !marketplaceSetsLoading}">
        <loadingSpinner mode="auto" v-if="marketplaceSetsLoading" :content="$t('common.Loading')"/>
        <b-table v-else
                 ref="marketplaceSetsTable"
                 class="data_table includes_dropdown"
                 :data="marketplaceSets"
                 :striped="true"
                 :hoverable="true"
                 :mobile-cards="true"
                 :paginated="isPaginated"
                 :per-page="perPage"
                 :current-page.sync="currentPage"
                 :pagination-simple="isPaginationSimple"
                 :pagination-position="paginationPosition">
          
          <template slot-scope="props">
            <b-table-column field="title" :label="$t('common.Marketplace_set')" :searchable="searchEnabled" width="45%">
              <span>{{props.row.title}}</span>
              <div v-if="props.row.description" class="meta">
                <span>{{props.row.description}}</span>
              </div>              
            </b-table-column>
            <b-table-column field="nr_of_categories_included" :label="$t('common.Number_of_categories_included')" :searchable="false" width="15%">
              <span>{{props.row.nr_of_categories_included}} {{props.row.nr_of_categories_included !== 1 ? $t('common.categories') : $t('common.category')}}</span>
            </b-table-column>            
            <b-table-column field="nr_of_companies_with_access" :label="$t('common.Number_of_companies_with_access')" :searchable="false" width="15%">
              <span>{{props.row.nr_of_companies_with_access}} {{props.row.nr_of_companies_with_access !== 1 ? $t('common.companies') : $t('common.company')}}</span>
            </b-table-column>             
            <b-table-column field="is_approved" :label="$t('Approved')" class="is_approved" width="5%">
              <div class="d-flex align-items-center">
                <i class="fas" v-bind:class="{'fa-check' : props.row.is_approved, 'fa-times' : !props.row.is_approved}"></i>
              </div>                                                          
            </b-table-column>                                                     
            <b-table-column field="actions" label="" width="5%" :visible="checkPermission('admin.mpsets.edit') || checkPermission('admin.mpsets.approve') || checkPermission('admin.mpsets.disapprove')">
              <div class="d-flex justify-content-lg-end">
                <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                  <div slot="toggler-content">
                    <span class="d-flex align-items-center">
                      {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                    </span>
                  </div>

                  <CDropdownItem v-if="checkPermission('admin.mpsets.approve') && !props.row.is_approved">
                    <div class="d-flex align-items-center" @click="approveModal = true; approveModalData = props.row;">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-check"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('common.Approve_marketplace_set')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>
                  <CDropdownItem v-if="checkPermission('admin.mpsets.disapprove') && props.row.is_approved">
                    <div class="d-flex align-items-center" @click="disapproveModal = true; disapproveModalData = props.row;">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-times"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('common.Disapprove_marketplace_set')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>                  
                  <CDropdownItem v-if="checkPermission('admin.mpsets.edit')">
                    <div class="d-flex align-items-center" @click="openSidebarRight('marketplace_set_details', { marketplace_set_id_external: props.row.mp_learn_set_id_external, product_tag: 'learn' });">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-pen"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('common.Edit_marketplace_set')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>                                                                                                 
                </CDropdown>
              </div>
            </b-table-column>                                      
          </template>
          <template slot="empty">
            <div class="text-center">
              <span>{{$t('common.No_marketplace_sets_available')}}</span>
            </div>                
          </template>                
        </b-table>
      </CCol>
    </CRow>

    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="approveModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">            
          {{$t('common.Approve_marketplace_set')}} {{approveModalData.title}}
        </CCardHeader>
        <CCardBody>          
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('admin.Are_you_sure_to_approve_marketplace_set', { set_title : approveModalData.title})}}</span>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="approveMarketplaceSet(approveModalData.mp_learn_set_id_external);">
              <i class="fas fa-check mr-1"/>{{$t('common.Approve_marketplace_set')}}
            </CButton>
            <CButton color="secondary" @click="approveModal = false;">
              <i class="fas fa-times mr-1"/>{{$t('Cancel')}}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="disapproveModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">            
          {{$t('common.Disapprove_marketplace_set')}} {{disapproveModalData.title}}
        </CCardHeader>
        <CCardBody>          
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('admin.Are_you_sure_to_disapprove_marketplace_set', { set_title : disapproveModalData.title})}}</span>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="disapproveMarketplaceSet(disapproveModalData.mp_learn_set_id_external);">
              <i class="fas fa-check mr-1"/>{{$t('common.Disapprove_marketplace_set')}}
            </CButton>
            <CButton color="secondary" @click="disapproveModal = false;">
              <i class="fas fa-times mr-1"/>{{$t('Cancel')}}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'MarketplaceSets',
  props: ['platformPermissions'],
  components: {
    loadingSpinner
  },  
  data() {
    return {      
      marketplaceSets: [],
      marketplaceSetsLoading: false,
      marketplaceSetsLoaded: false,
      isPaginated: true,  
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,
      approveModal: false,
      approveModalData: {},
      disapproveModal: false,
      disapproveModalData: {}                 
    }
  },
  methods: {
    getMarketplaceSets() {
      // Start the loader
      if(this.marketplaceSetsLoaded === false) this.marketplaceSetsLoading = true;
      // Get the marketplace sets
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/learn/sets')
      .then(res => {      
        this.marketplaceSets = res.data.data;
        // Check if the pagination should be activated
        (this.marketplaceSets.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Stop the loader
        this.marketplaceSetsLoading = false;
        // Update the marketplaceSetsLoaded value
        this.marketplaceSetsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    approveMarketplaceSet(marketplaceSetIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/learn/set/' + marketplaceSetIdExternal + '/approve')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Marketplace_set_approved'), type: 'is-success', duration: 2000 });

        if(this.$refs !== undefined && this.$refs.marketplaceSetsTable !== undefined) {
          let setIndex = this.$refs.marketplaceSetsTable.data.findIndex(x => x.mp_learn_set_id_external == marketplaceSetIdExternal);
          // Update the set data in the table
          this.$refs.marketplaceSetsTable.data[setIndex].is_approved = true;
          // Force update
          this.$forceUpdate();          
        }
        // Close the approve modal
        this.approveModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    disapproveMarketplaceSet(marketplaceSetIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/learn/set/' + marketplaceSetIdExternal + '/disapprove')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Marketplace_set_disapproved'), type: 'is-success', duration: 2000 });

        if(this.$refs !== undefined && this.$refs.marketplaceSetsTable !== undefined) {
          let setIndex = this.$refs.marketplaceSetsTable.data.findIndex(x => x.mp_learn_set_id_external == marketplaceSetIdExternal);
          // Update the set data in the table
          this.$refs.marketplaceSetsTable.data[setIndex].is_approved = false;
          // Force update
          this.$forceUpdate();          
        }
        // Close the disapprove modal
        this.disapproveModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },     
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function() {
    this.getMarketplaceSets();
    
    this.$bus.$on('update_learn_marketplace_sets', () => {
      this.getMarketplaceSets();
    })
  },
  beforeDestroy() {
    this.$bus.$off('update_learn_marketplace_sets');
  }  
}
</script>