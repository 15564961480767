<template>
  <div class="payments">
    <div class="payment_filters pt-2_5 pr-1_5 pl-1_5">
      <CRow class="m-0" :gutters="false">
        <CCol class="p-0 pr-1_5 pl-1_5 search">
          <label><b>{{$t('payments.Search_for_payment')}}</b></label>
          <CInput type="text" class="mb-0 searchbar" v-model="paymentFilters.description" v-debounce:1s="filterPayments"></CInput>
        </CCol>
        <CCol class="p-0 pr-1_5 pl-1_5 search">
          <label><b>{{$t('payments.Search_for_provider')}}</b></label>
          <CInput type="text" class="mb-0 searchbar" v-model="paymentFilters.provider" v-debounce:1s="filterPayments"></CInput>
        </CCol>
        <CCol class="p-0 pr-1_5 pl-1_5 search">
          <label><b>{{$t('payments.Search_for_amount')}}</b></label>
          <CInput type="text" class="mb-0 searchbar" v-model="paymentFilters.amount_incl_vat" v-debounce:1s="filterPayments"></CInput>
        </CCol>
        <CCol class="p-0 pr-1_5 pl-1_5 search">
          <label><b>{{$t('payments.Search_for_status')}}</b></label>
          <CInput type="text" class="mb-0 searchbar" v-model="paymentFilters.status" v-debounce:1s="filterPayments"></CInput>
        </CCol>
      </CRow>
    </div>
    <hr class="mt-2_5 mb-0">
    <div class="data_table_container">
      <CRow v-if="paymentsLoading === true">
        <CCol cols="12" lg="12">
          <loadingSpinner mode="auto" :content="$t('payments.Loading_payments')"/>
        </CCol>
      </CRow>      
      <CRow v-else>
        <CCol cols="12" lg="12" :class="{'pb-0' : paymentsLoaded}">          
          <b-table ref="paymentsTable"
                   class="data_table includes_dropdown"
                   :data="payments"
                   :striped="true"
                   :hoverable="true"
                   :mobile-cards="true"
                   :paginated="isPaginated"
                   :per-page="perPage"
                   :current-page.sync="currentPage"
                   :pagination-simple="isPaginationSimple"
                   :pagination-position="paginationPosition"
                   :total="totalItems"
                   backend-pagination
                   @page-change="onPageChange"
                   backend-sorting
                   :default-sort="[sortField, sortOrder]"
                   :default-sort-direction="defaultSortDirection"
                   :sort-icon="sortIcon"
                   :sort-icon-size="sortIconSize"
                   @sort="onTableSort"
                   detailed 
                   detail-key="payment_id_external" 
                   :show-detail-icon="true">
            
            <template slot-scope="props">
              <b-table-column field="description" :label="$t('payments.Payment')" :searchable="false" :sortable="true" width="30%">
                <span>{{props.row.description}}</span>
                <div class="meta mt-1">
                  <span>{{$t('payments.Payment_' + props.row.payment_event_type_tag)}}</span>
                </div>
              </b-table-column>
              <b-table-column field="payment_provider_name" :label="$t('payments.Provider')" :searchable="false" :sortable="true" width="12.5%">
                <span>{{props.row.payment_provider_name}}</span>
              </b-table-column>          
              <b-table-column field="amount_incl_vat" :label="$t('payments.Amount_incl_vat')" :searchable="false" :sortable="true" width="12.5%">
                <span class="count">&euro; {{formatToCurrency(props.row.amount_incl_vat)}}</span>
              </b-table-column>                        
              <b-table-column field="status" :label="$t('common.Status')" :searchable="false" :sortable="true" width="12.5%">
                <b-tag class="payment_status" v-bind:style="getPaymentStatusStyle(props.row.status)">
                  <i class="fa-solid" :class="getPaymentStatusIconClass(props.row.status)"/>{{$t('payments.Status_' + props.row.status)}}
                </b-tag>
              </b-table-column>
              <b-table-column field="created" :label="$t('common.Created')" :searchable="false" :sortable="true" width="12.5%">
                <span>{{props.row.created | moment("DD-MM-YYYY HH:mm")}}</span>
              </b-table-column>
              <b-table-column field="last_update" :label="$t('common.Last_update')" :searchable="false" :sortable="true" width="12.5%">
                <span>{{props.row.last_update | moment("DD-MM-YYYY HH:mm")}}</span>
              </b-table-column>
              <b-table-column field="actions" label="" width="5%" :visible="checkPermission('admin.company.payments.status') || checkPermission('admin.company.payments.invoice')">
                <div class="d-flex justify-content-lg-end">
                  <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                    <div slot="toggler-content">
                      <span class="d-flex align-items-center">
                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                      </span>
                    </div>

                    <CDropdownItem v-if="checkPermission('admin.company.wkrdata.paymentstatus') && props.row.status === 'open'">
                      <div class="d-flex align-items-center" @click="paymentStatusModal = true; paymentStatusModalData = props.row;">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fa-solid fa-check"></i>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('payments.Mark_payment_paid')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>
                    <CDropdownItem v-if="checkPermission('admin.company.wkrdata.paymentstatus') && props.row.status === 'paid'">
                      <div class="d-flex align-items-center" @click="paymentStatusModal = true; paymentStatusModalData = props.row;">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fa-solid fa-times"></i>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('payments.Mark_payment_open')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>
                    <CDropdownItem v-if="checkPermission('admin.company.payments.invoice') && props.row.invoice_code">
                      <div class="d-flex align-items-center" @click="downloadPaymentInvoice(props.row.invoice_code);">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fa-solid fa-download"></i>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('payments.Download_invoice')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>                                      
                  </CDropdown>
                </div>
              </b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
              <b-table class="data_table" 
                      :data="Object.values(props.row.payment_details)"
                      :striped="false" 
                      :hoverable="false" 
                      :mobile-cards="true" 
                      :paginated="false">
                
                <template slot-scope="props">
                  <b-table-column field="quantity" :label="$t('common.Quantity')" :searchable="false" :sortable="true" width="15%">
                    <span>{{props.row.quantity}}</span>
                  </b-table-column>
                  <b-table-column field="payment_detail_type_tag" :label="$t('common.Description')" :searchable="false" width="55%">
                    <span>{{$t('payments.Payment_detail_' + props.row.payment_detail_type_tag)}}</span>
                  </b-table-column>                
                  <b-table-column field="amount" :label="$t('common.Amount')" :searchable="false" :sortable="true" width="15%">
                    <span class="count">&euro; {{formatToCurrency(props.row.amount)}}</span>
                  </b-table-column>
                  <b-table-column field="total_amount" :label="$t('common.Total_amount')" :searchable="false" :sortable="true" width="15%">
                    <span class="count">&euro; {{formatToCurrency(props.row.total_amount)}}</span>
                  </b-table-column>                
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <span>{{$t('payments.No_payment_details_available')}}</span>
                  </div>                
                </template>                
              </b-table>

              <div class="payment_details_totals mt-3">
                <div class="d-flex">
                  <div class="p-2_5">
                    <span><b>{{$t('payments.Amount_excl_vat')}}</b></span>
                  </div>
                  <div class="p-2_5">
                    <span class="count">&euro; {{formatToCurrency(props.row.amount_excl_vat)}}</span>
                  </div>
                </div>
                <div v-if="props.row.vat_percentage > 0" class="d-flex">
                  <div class="p-2_5">
                    <span><b>{{props.row.vat_percentage}}% {{$t('payments.VAT')}}</b></span>
                  </div>
                  <div class="p-2_5">
                    <span class="count">&euro; {{formatToCurrency(props.row.amount_excl_vat * (props.row.vat_percentage / 100))}}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="p-2_5">
                    <span><b>{{$t('payments.Amount_incl_vat')}}</b></span>
                  </div>
                  <div class="p-2_5">
                    <span class="count">&euro; {{formatToCurrency(props.row.amount_incl_vat)}}</span>
                  </div>
                </div>
              </div>
            </template>
            <template slot="empty">
              <div class="text-center">
                <span>{{$t('payments.No_payments_available')}}</span>
              </div>                
            </template>                
          </b-table>
        </CCol>
      </CRow>
    </div>

    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="paymentStatusModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">                      
          <span v-if="paymentStatusModalData.status === 'paid'">{{$t('payments.Mark_payment_open')}}</span>
          <span v-else>{{$t('payments.Mark_payment_paid')}}</span>
        </CCardHeader>
        <CCardBody>          
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0">
              <span v-if="paymentStatusModalData.status === 'paid'">{{$t('payments.Are_you_sure_to_mark_as_paid')}}</span>
              <span v-else>{{$t('payments.Are_you_sure_to_mark_as_open')}}</span>
            </CCol>
          </CRow>
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span><b>{{$t('common.Description')}}</b>: {{paymentStatusModalData.description}}</span>
              <span class="d-block"><b>{{$t('payments.Amount_incl_vat')}}</b>: &euro;{{formatToCurrency(paymentStatusModalData.amount_incl_vat)}}</span>
            </CCol>
          </CRow>          
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="updatePaymentStatus(paymentStatusModalData);">              
              <span v-if="paymentStatusModalData.status === 'paid'"><i class="fas fa-check mr-1"/>{{$t('payments.Mark_payment_open')}}</span>
              <span v-else><i class="fas fa-times mr-1"/>{{$t('payments.Mark_payment_paid')}}</span>
            </CButton>
            <CButton color="secondary" @click="paymentStatusModal = false;">
              <i class="fas fa-times mr-1"/>{{$t('Cancel')}}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'CompanyPayments',
  props: ['platformPermissions'],
  components: {
    loadingSpinner
  },
  data() {
    return {
      payments: [],
      paymentsLoading: false,
      paymentsLoaded: false,
      paymentFilters: {
        description: '',
        provider: '',
        amount_incl_vat: '',
        status: ''
      },
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',      
      sortField: 'last_update',
      sortOrder: 'desc',
      defaultSortDirection: 'desc',  
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: false,
      totalItems: 0,
      startIndex: 0,
      paymentStatusModal: false,
      paymentStatusModalData: {}
    }
  },
  methods: {
    getPayments() {
      // Start the loader
      if(this.paymentsLoaded === false) this.paymentsLoading = true;
      // Set the params
      let params = {};
      params.payment_limit = this.perPage;
      params.payment_filters = this.paymentFilters;
      params.payment_filters.sort_field = this.sortField;
      params.payment_filters.sort_order = this.sortOrder;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/payments/' + this.startIndex, params)
      .then(res => {              
        // Clear the payments array
        this.payments = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_payments;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the payments to the payments array
        this.payments = res.data.data.payments;
        // Enable the pagination if necessary
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.paymentsLoading = false;  
        // Update the paymentsLoaded value
        this.paymentsLoaded = true;                 
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updatePaymentStatus(paymentStatusModalData) {
      let params = {};
      params.paymentData = {}
      params.paymentData.invoice_code = paymentStatusModalData.invoice_code;
      (paymentStatusModalData.status === 'paid') ? params.paymentData.payment_status = 'open' : params.paymentData.payment_status = 'paid';
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/payment/' + paymentStatusModalData.payment_id_external + '/status', params)
      .then(res => {
        if(params.paymentData.payment_status = 'paid') {
          this.$buefy.toast.open({ message: this.$t('payments.Marked_payment_paid'), type: 'is-success', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.$t('payments.Marked_payment_open'), type: 'is-success', duration: 2000 });
        }

        if(this.$refs !== undefined && this.$refs.paymentsTable !== undefined) {
          // Get the data index
          let dataIndex = this.$refs.paymentsTable.data.findIndex(x => x.payment_id_external == paymentStatusModalData.payment_id_external);
          // Update the WKR data in the table          
          this.$refs.paymentsTable.data[dataIndex].status = params.paymentData.payment_status;
          // Force update
          this.$forceUpdate();
        }
        // Close the payment status modal
        this.paymentStatusModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    downloadPaymentInvoice(invoiceCode) {
      let params = {};
      params.invoice_code = invoiceCode;
            
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/payment/invoice/download', params)
      .then(res => {
        let invoiceData = res.data.data;        
        // Create a new 'a' element
        const invoiceDownloadLink = document.createElement('a');
        // Set the base64 string of the PDF as href of the new element
        invoiceDownloadLink.href = 'data:application/octet-stream;base64,' + invoiceData.data;
        // Set the download attribute
        invoiceDownloadLink.setAttribute('download', invoiceData.file_name);
        // Append the invoiceDownloadLink to the body
        document.body.appendChild(invoiceDownloadLink);
        // Click the invoiceDownloadLink
        invoiceDownloadLink.click();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },  
    onPageChange(page) {
      // Set the startIndex value
      (page > 1) ? this.startIndex = (page - 1)*10 : this.startIndex = 0;
      // Get the company payments
      this.getPayments();
    },
    onTableSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // Reset the pagination;
      this.resetPagination();
      // Get the payments
      this.getPayments();
    },    
    filterPayments() {
      // Reset the pagination;
      this.resetPagination();
      // Get the payments
      this.getPayments();
    },
    resetPagination() {
      this.currentPage = 1;
      this.startIndex = 0;
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },    
    getPaymentStatusStyle(statusTag) {
      if(statusTag === 'open' || statusTag === 'pending') {
        return { 'background-color': '#fff2d4', 'color': '#8b6900' };        
      } else if(statusTag === 'paid') {
        return { 'background-color': '#ebf7ea', 'color': '#1e681d' };
      } else if(statusTag === 'failed') {
        return { 'background-color': '#ffe6ea', 'color': '#99001c' };
      } else if(statusTag === 'cancelled' || statusTag === 'expired') {
        return { 'background-color': '#ececf0', 'color': '#46474e' };
      }
    },
    getPaymentStatusIconClass(statusTag) {
      if(statusTag === 'open' || statusTag === 'pending') {
        return 'fa-arrow-rotate-right';
      } else if(statusTag === 'paid') {
        return 'fa-circle-check';
      } else if(statusTag === 'failed') {
        return 'fa-circle-xmark';
      } else if(statusTag === 'cancelled') {
        return 'fa-circle-exclamation';
      } else if(statusTag === 'expired') {        
        return 'fa-clock';
      }
    },    
    formatToCurrency(number) {
      // Round the number to two decimal places
      var roundedNumber = Math.round(number * 100) / 100;
      // Format the rounded number      
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(roundedNumber);
    }
  },
  mounted: function() {
    this.getPayments();

    this.$bus.$on('update_company_payments', () => {      
      this.getPayments();
    })    
  },
  beforeDestroy() {
    this.$bus.$off('update_company_payments');
  }  
}
</script>