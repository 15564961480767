<template>
  <div>
    <CRow class="m-0">
      <CCol cols="12" xl="12" class="pb-0 text-left">
        <CButton class="btn_small" color="primary" @click="openSidebarRight('default_platform_event_details', { platform_default_event_config_id_external: null });">    
          <span><i class="fas fa-plus mr-1"/>{{ $t('admin.Add_default_event') }}</span>
        </CButton>
      </CCol>     
    </CRow>        
    <CRow class="m-0 events">
      <CCol cols="12" lg="12" md="12" :class="{'pr-0 pl-0 pb-0' : !eventsLoading}">
        <loadingSpinner mode="auto" v-if="eventsLoading" :content="$t('common.Loading')"/>
        <b-table v-else class="data_table includes_dropdown" :data="events" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
          <template slot-scope="props">
            <b-table-column field="description" :label="$t('common.Name')" :searchable="searchEnabled" :sortable="true">  
              <div class="d-flex align-items-center">
                <div class="event_icon mr-2_5">
                  <img v-if="props.row.event_type_image_id && !props.row.custom_event" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/global/' + props.row.event_type_image_id + '/' + clientToken"/>                            
                  <img v-else-if="props.row.event_type_image_id && props.row.custom_event" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-custom/' + props.row.tag + '/' + props.row.event_type_image_id + '/' + clientToken"/>
                  <img v-else :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/common/calendar.png/' + clientToken"/>
                </div>
                <b>{{props.row.description}}</b>
              </div>
            </b-table-column>
            <b-table-column field="tag" :label="$t('common.Tag')" :searchable="searchEnabled" :sortable="true">  
              <span>{{props.row.tag}}</span>
            </b-table-column>
            <b-table-column field="product.product_name" :label="$t('common.Part_of_product')" :searchable="searchEnabled" :sortable="true">  
              <span>{{props.row.product.product_name}}</span>
            </b-table-column>            
            <b-table-column field="tag" :label="$t('common.Environment')" :searchable="searchEnabled" :sortable="true">  
              <span>{{props.row.environment_name ? props.row.environment_name : '-'}}</span>
            </b-table-column>                        
            <b-table-column field="actions">
              <div class="d-flex justify-content-lg-end">                          
                <CDropdown color="primary"
                            toggler-text=""
                            :caret="false"
                            class="ml-2 table_actions_dropdown">
                  <div slot="toggler-content">
                    <span class="d-flex align-items-center">
                      {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                    </span>
                  </div>

                  <CDropdownItem>
                    <div class="d-flex align-items-center" @click="openSidebarRight('default_platform_event_details', { platform_default_event_config_id_external: props.row.platform_default_event_config_id_external})">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-sliders"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('admin.Edit_default_config')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>
                  <CDropdownItem v-if="props.row.email_preview_available">
                    <div class="d-flex align-items-center" @click="showDefaultEmailContentPreview(props.row.platform_default_event_email_content_id_external)">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-search"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('admin.Preview_default_email')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>
                  <CDropdownItem>
                    <div class="d-flex align-items-center" @click="showDefaultEmailContentEditPage(props.row.platform_default_event_email_content_id_external);">
                      <div class="post_action_icon text-center mr-1">
                        <i class="fas fa-pen"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{$t('admin.Edit_default_email')}}</span>
                      </div>
                    </div>
                  </CDropdownItem>
                </CDropdown>
              </div>
            </b-table-column>                        
          </template>                
        </b-table>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'DefaultEmails',
  components: {
    loadingSpinner
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      events: [],
      eventsLoading: false,
      eventsLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      searchEnabled: true,
      currentPage: 1,
      perPage: 10      
    }
  },
  methods: {
    getDefaultEmailContent() {
      // Start the loader
      if(this.eventsLoaded === false) this.eventsLoading = true;
      // Get the events
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/events/default')
      .then(res => {
        this.events = res.data.data;
        // Stop the loader
        this.eventsLoading = false;
        // Update the eventsLoaded value
        this.eventsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    showDefaultEmailContentPreview(defaultEmailContentIdExternal) {
      this.$bus.$emit('show_default_email_content_preview', defaultEmailContentIdExternal);      
    },
    showDefaultEmailContentEditPage(defaultEmailContentIdExternal) {
      this.$router.push({path: '/admin/events/default/email/' + defaultEmailContentIdExternal});
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    
    this.getDefaultEmailContent();
    
    this.$bus.$on('default_event_added', () => {
      this.getDefaultEmailContent();
    })

    this.$bus.$on('default_event_config_updated', () => {
      this.getDefaultEmailContent();
    })    
  },
  beforeDestroy() {
    this.$bus.$off('default_event_added');
    this.$bus.$off('default_event_config_updated');
  }
}
</script>
