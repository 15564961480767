<template>
  <CRow>
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-tabs class="super_admin_tabs no_borders paginated mb-0" type="is-boxed" :animated="false" v-model="activeMarketplaceTab">        
        <b-tab-item>
          <template slot="header">
            <span>SatisQuestions</span>
          </template>
          <template>
            <measureMarketplaceSets :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <b-tab-item>
          <template slot="header">
            <span>Spark Moments - Spark Shop</span>
          </template>
          <template>
            <loyaltyMarketplaceSets :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <b-tab-item>
          <template slot="header">
            <span>Spark Moments - Spark Designs</span>
          </template>
          <template>
            <loyaltyMomentsMarketplaceSets :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>        
        <b-tab-item>
          <template slot="header">
            <span>Brain Base</span>
          </template>
          <template>
            <knowledgeMarketplaceSets :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <b-tab-item>
          <template slot="header">
            <span>Grow Station</span>
          </template>
          <template>
            <learnMarketplaceSets :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <b-tab-item>
          <template slot="header">
            <span>Service Spot</span>
          </template>
          <template>
            <serviceMarketplaceSets :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <b-tab-item>
          <template slot="header">
            <span>Hub</span>
          </template>
          <template>
            <hubMarketplaceSets :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
      </b-tabs>
    </CCol>    
  </CRow>
</template>

<script>

import measureMarketplaceSets from '@/views/admin/marketplace/sets/Measure.vue';
import loyaltyMarketplaceSets from '@/views/admin/marketplace/sets/Loyalty.vue';
import loyaltyMomentsMarketplaceSets from '@/views/admin/marketplace/sets/LoyaltyMoments.vue';
import knowledgeMarketplaceSets from '@/views/admin/marketplace/sets/Knowledge.vue';
import learnMarketplaceSets from '@/views/admin/marketplace/sets/Learn.vue';
import serviceMarketplaceSets from '@/views/admin/marketplace/sets/Service.vue';
import hubMarketplaceSets from '@/views/admin/marketplace/sets/Hub.vue';

export default {
  name: 'Marketplace',
  props: ['platformPermissions'],
  components: {
    measureMarketplaceSets,
    loyaltyMarketplaceSets,
    loyaltyMomentsMarketplaceSets,
    knowledgeMarketplaceSets,
    learnMarketplaceSets,
    serviceMarketplaceSets,
    hubMarketplaceSets
  },
  data() {
    return {
      activeMarketplaceTab: 0        
    }
  }
}
</script>