<template>
  <div>
    <CRow v-if="platformRolesLoaded && checkRole('super_admin')" class="admin">
      <CCol cols="12" lg="12">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                <span>{{$t('admin.Options')}}</span>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">                    
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <b-tabs class="super_admin_tabs no_borders paginated mb-0" type="is-boxed" :animated="false" v-model="activeTab">
                  <b-tab-item>
                    <template slot="header">
                      <span>{{$t('admin.Admin_company')}}</span>
                    </template>
                    <template>
                      <CRow class="m-0">
                        <CCol cols="12" lg="12">
                          <label><b>{{$t('admin.Set_admin_company')}}</b></label>
                          <div class="set_admin_company d-flex">
                            <div @click.native="resetCompanies()" class="flex-grow-1">
                              <multiselect v-model="adminCompany"
                                          label="company_name" 
                                          track-by="company_id_external" 
                                          :placeholder="$t('Search_for_a') + ' ' + $t('common.company')"
                                          open-direction="bottom" 
                                          :options="companies" 
                                          :multiple="false" 
                                          :searchable="true" 
                                          :loading="false" 
                                          :internal-search="false"
                                          :clear-on-select="true"
                                          :close-on-select="true"
                                          :options-limit="300"
                                          :max-height="600"
                                          :show-no-results="true"
                                          :hide-selected="false"
                                          @search-change="asyncFindCompany"
                                          @input="setAdminCompany">
                                <span slot="noResult">{{ $t('common.No_companies_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                
                              </multiselect>
                            </div>
                            <div class="pl-2">
                              <CButton color="primary" @click="resetAdminCompany()" class="m-0">
                                <i class="fa-solid fa-rotate-left"></i>
                              </CButton>
                            </div>
                          </div>
                        </CCol>    
                      </CRow>
                      <div v-if="companyLoaded">
                        <hr class="m-0">
                        <CRow>
                          <CCol cols="12" lg="12" class="pt-0 pb-0">
                            <b-tabs class="super_admin_tabs no_borders paginated mb-0" type="is-boxed" :animated="false" v-model="activeCompanyTab">
                              <b-tab-item>
                                <template slot="header">
                                  <span>{{$t('admin.Company_details')}}</span>
                                </template>
                                <template>
                                  <CompanyDetails :countries="countries" :languages="languages" :adminIsDistributor="userIsDistributor"/>
                                </template>
                              </b-tab-item>
                              <b-tab-item>
                                <template slot="header">
                                  <span>{{$t('admin.Company_licensing')}}</span>
                                </template>
                                <template>
                                  <CompanyLicensing/>
                                </template>
                              </b-tab-item>                              
                              <b-tab-item>
                                <template slot="header">
                                  <span>{{$t('admin.Company_environment')}}</span>
                                </template>
                                <template>
                                  <CompanyEnvironment :companyData="company" :environments="environments"/>
                                </template>
                              </b-tab-item>
                              <b-tab-item v-if="!userIsDistributor">
                                <template slot="header">
                                  <span>{{$t('admin.Header_customizations')}}</span>
                                </template>
                                <template>
                                  <CompanyHeaderCustomizations :companyData="company"/>
                                </template>
                              </b-tab-item>
                              <b-tab-item>
                                <template slot="header">
                                  <span>{{$t('admin.Company_financial_details')}}</span>
                                </template>
                                <template>
                                  <CompanyFinancialDetails :companyData="company" :countries="countries" :adminIsDistributor="userIsDistributor"/>
                                </template>
                              </b-tab-item>
                              <b-tab-item v-if="modules.employee_loyalty_programme == 1">
                                <template slot="header">
                                  <span>{{$t('admin.Payments')}}</span>
                                </template>
                                <template>
                                  <CompanyPayments :platformPermissions="platformPermissions"/>
                                </template>
                              </b-tab-item>
                              <b-tab-item v-if="modules.employee_loyalty_programme == 1 && modules.loyalty_wkr == 1">
                                <template slot="header">
                                  <span>{{$t('admin.WKR')}}</span>
                                </template>
                                <template>
                                  <CompanyWKR :platformPermissions="platformPermissions"/>
                                </template>
                              </b-tab-item>
                              <b-tab-item>
                                <template slot="header">
                                  <span>{{$t('admin.Demo')}}</span>
                                </template>
                                <template>
                                  <CompanyDemo :companyData="company" :platformPermissions="platformPermissions" :modules="modules"/>
                                </template>
                              </b-tab-item>                            
                            </b-tabs>
                          </CCol>    
                        </CRow>
                      </div>
                    </template>
                  </b-tab-item>
                  <b-tab-item v-if="!userIsDistributor">
                    <template slot="header">
                      <span>{{$t('admin.Marketplace')}}</span>
                    </template>
                    <template>
                      <Marketplace :platformPermissions="platformPermissions"/>
                    </template>
                  </b-tab-item>
                  <b-tab-item>
                    <template slot="header">
                      <span>{{$t('admin.New_company')}}</span>
                    </template>
                    <template>
                      <NewCompany :environments="environments"/>
                    </template>
                  </b-tab-item>
                  <b-tab-item>
                    <template slot="header">
                      <span>{{$t('admin.Welcome_email')}}</span>
                    </template>
                    <template>
                      <SendWelcomeEmail/>
                    </template>
                  </b-tab-item>
                  <b-tab-item v-if="!userIsDistributor">
                    <template slot="header">
                      <span>{{$t('admin.Global_header_customizations')}}</span>
                    </template>
                    <template>
                      <GlobalHeaderCustomizations :platformPermissions="platformPermissions"/>
                    </template>
                  </b-tab-item>                  
                  <!-- <b-tab-item v-if="!userIsDistributor">
                    <template slot="header">
                      <span>{{$t('admin.Default_event_settings')}}</span>
                    </template>
                    <template>
                      <DefaultEvents/>
                    </template>
                  </b-tab-item> -->
                </b-tabs>
              </CCol>
            </CRow>        
          </CCardBody>
        </CCard>    
      </CCol>
    </CRow>
    <noPermission v-else-if="platformRolesLoaded" trigger="permission"/>

    <b-modal class="email_preview position-absolute" :can-cancel="['x']" :active.sync="emailPreviewModal" :width="600" scroll="keep">
      <CCard class="mb-0">
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-html="emailPreviewModalData.email_content"></div>           
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="secondary" @click="emailPreviewModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>
  </div>  
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import noPermission from '@/components/common/noPermission.vue';

import CompanyDetails from '@/views/admin/company/CompanyDetails.vue';
import CompanyLicensing from '@/views/admin/company/CompanyLicensing.vue';
import CompanyEnvironment from '@/views/admin/company/CompanyEnvironment.vue';
import CompanyFinancialDetails from '@/views/admin/company/CompanyFinancialDetails.vue';
import CompanyHeaderCustomizations from '@/views/admin/company/CompanyHeaderCustomizations.vue';
import CompanyPayments from '@/views/admin/company/CompanyPayments.vue';
import CompanyWKR from '@/views/admin/company/CompanyWKR.vue';
import CompanyDemo from '@/views/admin/company/CompanyDemo.vue';

import Marketplace from '@/views/admin/marketplace/Marketplace.vue';
import NewCompany from '@/views/admin/NewCompany.vue';
import SendWelcomeEmail from '@/views/admin/SendWelcomeEmail.vue';
import GlobalHeaderCustomizations from '@/views/admin/GlobalHeaderCustomizations.vue';
import DefaultEvents from '@/views/admin/events/DefaultEvents.vue';

export default {
  name: 'Admin',
  components: {
    Multiselect,
    noPermission,
    CompanyDetails,
    CompanyLicensing,
    CompanyEnvironment,
    CompanyFinancialDetails,
    CompanyHeaderCustomizations,
    CompanyPayments,
    CompanyWKR,
    CompanyDemo,
    Marketplace,
    NewCompany,
    SendWelcomeEmail,
    GlobalHeaderCustomizations,
    DefaultEvents
  },
  data() {
    return {
      modules: {
        employee_loyalty_programme: 0,
        loyalty_wkr: 0        
      },
      activeTab: 0,
      activeCompanyTab: 0,
      platformRoles: [],
      platformRolesLoaded: false,
      platformPermissions: [],
      adminCompany: null,
      company: {},
      companyLoaded: false,      
      companies: [],
      environments: [],
      countries: [],
      languages: [],
      emailPreviewModal: false,
      emailPreviewModalData: {},
      userIsDistributor: true
    }
  },
  methods: {
    getAdminCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
        // Set the adminCompany object
        this.adminCompany = {
          company_id_external: this.company.details.company_id_external,
          company_name: this.company.details.company_name,
          brand_name: this.company.details.brand_name
        }
        // Update the companyLoaded value
        this.companyLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setAdminCompany(data) {
      let params = {};
      params.company_id_external = data.company_id_external;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/impersonate', params)
      .then(res => {
        let newCompanyDetails = res.data.data;
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Admin_company_set'), type: 'is-success', duration: 1000 });
        // Update the data in localStorage
        this.updateLocalStorageData(newCompanyDetails);
        // Refresh the page
        window.location.reload();
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    resetAdminCompany() {
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/original')
      .then(res => {
        let originalCompanyDetails = res.data.data;
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Admin_company_reset'), type: 'is-success', duration: 1000 });
        // Update the data in localStorage
        this.updateLocalStorageData(originalCompanyDetails);
        // Refresh the page
        window.location.reload();
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    resetCompanies() {
      this.companies = [];
    },
    updateLocalStorageData(data) {
      // Clear items from localStorage for better transition between companies
      localStorage.removeItem('companyImageId');
      localStorage.removeItem('headerImageId');
      localStorage.removeItem('environmentTag');
      // Update the companyIdExternal data in localStorage
      localStorage.setItem('companyIdExternal', data.company_id_external);
      // Update the companyImageId data in localStorage
      if(data.company_image_id) localStorage.setItem('companyImageId', data.company_image_id);
      // Update the headerImageId data in localStorage
      if(data.header_image_id) localStorage.setItem('headerImageId', data.header_image_id); 
      // Update the environmentTag data in localStorage
      if(data.environment_tag) localStorage.setItem('environmentTag', data.environment_tag);
      // Remove the organizationViewToActivate data from localStorage
      localStorage.removeItem('organizationViewToActivate');      
      // Remove the connectChatToActivate data from localStorage
      localStorage.removeItem('connectChatToActivate');
      // Remove the activeFormCategory data from localStorage
      localStorage.removeItem('activeFormCategory');
      // Remove the serviceQuestionToActivate data from localStorage
      localStorage.removeItem('serviceQuestionToActivate');      
      // Remove the serviceQuestionEmployeeFilter data from localStorage
      localStorage.removeItem('serviceQuestionEmployeeFilter');
      // Remove the serviceQuestionFolderFilter data from localStorage
      localStorage.removeItem('serviceQuestionFolderFilter');
    },    
    asyncFindCompany(searchTerm) {
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/companies/' + searchTerm)
        .then(res => {      
          this.companies = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    },
    getEnvironments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/environments')
      .then(res => {      
        this.environments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCountries() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/countries')
      .then(res => {
        this.countries = res.data.data;
      })
      .catch(err => {
        console.error(err);
      });
    },
    getPlatformLanguages() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/languages')
      .then(res => {      
        this.languages = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },       
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showDefaultEmailContentPreview(defaultEmailContentIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/events/default/email/' + defaultEmailContentIdExternal + '/preview')
      .then(res => {
        // Set the emailPreviewModalData
        this.emailPreviewModalData = res.data.data;
        // Open the modal
        this.emailPreviewModal = true;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    }    
  },
  async mounted() {
    this.checkModules();
    this.getPlatformRoles();
    this.getPlatformPermissions();
    this.getEnvironments();
    this.getCountries();
    this.getPlatformLanguages();

    this.getAdminCompanyDetails();

    this.userIsDistributor = await this.checkDistributor();

    this.$bus.$on('company_details_updated', () => {      
      this.getAdminCompanyDetails();
    })    

    this.$bus.$on('module_status_updated', () => {      
      this.checkModules();
    })

    this.$bus.$on('show_default_email_content_preview', (defaultEmailContentIdExternal) => {
      this.showDefaultEmailContentPreview(defaultEmailContentIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('company_details_updated');
    this.$bus.$off('module_status_updated');
    this.$bus.$off('show_default_email_content_preview');
  }  
}
</script>