<template>
  <CRow class="m-0">
    <CCol cols="12" lg="12" class="pb-0">
      <label class="d-block"><b>{{$t('admin.Product_has_not_been_set_up_for_company', { product_name : productName, company_name : companyData.details.company_name })}}</b></label>
      <CButton color="primary" @click="setUpCompanyProduct()" class="m-0">
        <span>{{$t('admin.Set_up_product', {product_name : productName})}}<i class="fa-solid fa-rotate-left ml-1"></i></span>        
      </CButton>        
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SetUpCompanyProduct',
  props: ['companyData', 'productTag'],
  watch: {
    productTag: {
      handler() {
        if(this.productTag) this.productName = this.$t('products.' + this.productTag);
      },
      deep: true,
      immediate: true
    }
  },   
  data() {
    return {
      productName: null
    }
  },
  methods: {
    setUpCompanyProduct() {
      let params = {};
      params.company_id_external = this.companyData.details.company_id_external;

      axios.post(process.env.VUE_APP_API_URL + '/v1/superadmin/setup/company/' + this.productTag, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('admin.Set_up_successful', { product_name : this.productName}), type: 'is-success', duration: 2000 });
        // Emit the set_up_successful event based on the product tag
        this.$bus.$emit(this.productTag + '_set_up_successful');
      })
      .catch(err => {
        console.error(err); 
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);    
    }
  }
}
</script>